var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('BreadCrumbs',{attrs:{"elements":[{ link: 'Blog', name: 'Blog' }]}}),_c('section',{staticClass:"articleSection"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"headline"},[_c('h1',[_vm._v("Cikkek")]),_c('hr'),(_vm.model && _vm.model.meta)?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('ul',{staticClass:"pagination justify-content-center my-4"},[_vm._l((_vm.model.meta
                                        .links),function(item,index){return [(
                                            item.label ===
                                                '&laquo; Previous'
                                        )?_c('li',{key:'pagination-prev-' + index,staticClass:"page-item",class:{
                                            disabled: item.url === null
                                        }},[_c('a',{staticClass:"page-link",staticStyle:{"cursor":"pointer"},attrs:{"aria-label":"Previous"},on:{"click":_vm.minusPage}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("«")])])]):(
                                            item.label === 'Next &raquo;'
                                        )?_c('li',{key:'pagination-next-' + index,staticClass:"page-item",class:{
                                            disabled: item.url === null
                                        }},[_c('a',{staticClass:"page-link",staticStyle:{"cursor":"pointer"},attrs:{"aria-label":"Next"},on:{"click":_vm.plusPage}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("»")])])]):_c('li',{key:'pagination-' + index,staticClass:"page-item",class:{
                                            active: item.active
                                        }},[_c('a',{staticClass:"page-link",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.setPage(item.label)}}},[_vm._v(_vm._s(item.label))])])]})],2)])])]):_vm._e()]),_c('div',{staticClass:"row"},[_vm._l((_vm.model.data),function(item,index){return [_c('BlogRow',{key:'blog-' + index,attrs:{"blog":item}})]})],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }